/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:31955bdb-04a4-4565-8dc1-00709bc1847f",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_ACm1Xqo2r",
    "aws_user_pools_web_client_id": "1robuq100s5aa9q7ova84d423r",
    "oauth": {}
};


export default awsmobile;
